
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import Popup from '@/general/components/common/Popup.vue'
import {
  GetShipments_GetShipment_consignments_actors_delivery,
  GetShipments_GetShipment_consignments_actors_pickup
} from '@/generated/GetShipments'

@Component({
  components: {
    InfoCard,
    Popup
  }
})
export default class ActorActionInfo extends Vue {
  @Prop({ required: true }) action: GetShipments_GetShipment_consignments_actors_pickup | GetShipments_GetShipment_consignments_actors_delivery
  @Prop({ required: true }) header: string
}
