


















































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import Popup from '@/general/components/common/Popup.vue'
import { Header } from '@/general/models/Vuetify'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import JsBarcode from 'jsbarcode'
import {
  GetShipments_GetShipment_consignments,
  GetShipments_GetShipment_consignments_containers, GetShipments_GetShipment_consignments_containers_packets
} from "@/generated/GetShipments";
import AuthenticationService from '@/general/services/AuthenticationService'

@Component({
  components: {
    InfoCard,
    BaseDataTable,
    Popup
  }
})
export default class ShipmentArticles extends Mixins(AuthenticationService) {
  @Prop() consignment: GetShipments_GetShipment_consignments
  @Prop({ default: true }) desktop: boolean
  @Prop({ default: 'shipment.single' }) title: string
  
  instructions(): string[] {
    return (this.consignment.actors.flatMap(a => [a.pickup.instructions, a.delivery.instructions]).filter(s => !!s) as string[]).flatMap(s => s.split('\n'))
  }

  showInstructions = false

  mounted() {
    ;(this.consignment.containers ? this.consignment.containers : []).forEach((value, index) => {
      if (this.desktop && value.barcode) {
        this.barcode(index, value.barcode!, false)
      }
    })
  }

  barcode(index: number, id: string, hover: boolean = false) {
    JsBarcode('#barcode-' + index, id, { displayValue: true, height: hover ? 100 : 20 })
  }

  consolidateColli(packets: GetShipments_GetShipment_consignments_containers_packets[]): number {
    let result = 0
    packets.forEach(packet => {
      if (packet.quantity) {
        result = result + Number(packet.quantity!!)
      }
    })
    return result
  }

  headers = [
    new Header('shipment.containers.fields.packQuantity', 'quantity'),
    new Header('shipment.containers.fields.packSize', 'sizePerPack'),
    new Header('shipment.containers.fields.weight', 'weight'),
    new Header('scan.description', 'description'),
    new Header('common.value', 'value'),
    new Header('shipment.containers.fields.hsCode', 'hsCode')
  ]

  getHeaders2(container: GetShipments_GetShipment_consignments_containers): Header[] {
    const headers: Header[] = []
    this.headers.forEach((header: Header) => {
      // If there are individual items specified, calculate the headers for the items; else we display the 'general' goodsitem
      if (this.displayColumn(container.packets.flatMap(packet => packet.items).length ? container.packets.flatMap(packet => packet.items) : container.packets, header.value)) headers.push(header)
    })
    return headers
  }

  displayColumn(items: any[], variable: string): boolean {
    for (let item of items) {
      if ((item as any)[variable]) {
        return true
      }
    }
    return false
  }

  getHeaderForContainer(container: GetShipments_GetShipment_consignments_containers) {
    return [
      [container.quantity, container.type?.type].filter(strings => strings).join(" "),
      container.dimensions?.length ? container.dimensions.map(dimension =>
          this.$options.filters!!.unit(' ' + dimension.value + dimension.unitCode)
      ) : null,
      // container.totalWeight ? this.$options.filters!!.unit(container.totalWeight) : null,
      this.consolidateColli(container.packets) ? this.consolidateColli(container.packets) + " colli" : 0,
      this.desktop ? null : container.packets.flatMap(packet => packet.items).map(items => items.description).filter(description => description)
    ].filter(value => value).join(" | ")
  }
}
