














import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import Popup from '@/general/components/common/Popup.vue'
import {
  GetShipments_GetShipment_consignments,
  GetShipments_GetShipment_consignments_actors,
  GetShipments_GetShipment_consignments_actors_delivery,
} from "@/generated/GetShipments";
import ActorActionInfo from "@/modules/shipments/views/detail/components/ActorActionInfo.vue";

@Component({
  components: {
    ActorActionInfo,
    InfoCard,
    Popup
  }
})
export default class CurrentStage extends Vue {
  @Prop({ required: true }) value: GetShipments_GetShipment_consignments
  @Prop({ required: false, default: false }) column: boolean

  location: GetShipments_GetShipment_consignments_actors_delivery | null | undefined = null

  toggle(clickedFrom: Boolean) {
    if (this.currentTransporter()) {
      let transporter = this.currentTransporter() as GetShipments_GetShipment_consignments_actors
      if (this.hasExtraInfo(clickedFrom ? transporter.pickup : transporter.delivery)) {
        this.location = clickedFrom ? transporter.pickup : transporter.delivery
      }
    }
  }

  // @todo fix location any
  private navigateToAddress(location: any) {
    // let address = encodeURIComponent([location.street, location.number, location.zipcode, location.city].filter(string => string).join(","))
    return "https://www.google.com/maps/dir/?api=1&destination="
  }

  currentTransporter(next = 0) {
    let stage = 0
    this.value.actors.forEach((value: GetShipments_GetShipment_consignments_actors, index: number) => {
      if (value.pickup?.status && value.delivery?.status ) stage = index + 1
    })
    const actor = this.value.actors[Math.max(stage - 1 + next, next)]
    if (actor) {
      return actor
    } else {
      return null
    }
  }

  hasExtraInfo(location: GetShipments_GetShipment_consignments_actors_delivery) {
    return location.instructions || location.person
  }
}
